
import { defineComponent } from "vue";
import { newsCollection } from "@/plugins/firebase";
import { NewsItem } from "@/apiServices/interface";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import { truncateString } from "@/plugins/helpers/stringHelpers";
import ApiController from "@/apiServices/ApiController";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "NewsOverview",
  data() {
    return {
      newsList: [] as NewsItem[],
      pendingRequest: false,
      pageLimit: 20,
      newsItemCounter: 20,
      lastestDoc: null as any,
      endOfList: false,
      timestampToDate,
      truncateString
    };
  },
  methods: {
    handleScroll() {
      const { scrollTop, offsetHeight } = document.documentElement;
      const { innerHeight } = window;
      const bottomOfWindow = Math.round(scrollTop) + innerHeight === offsetHeight;

      if (bottomOfWindow) {
        this.getNewsItems();
      }
    },
    async getNewsItems() {
      if (this.pendingRequest || this.endOfList) {
        return;
      }
      this.pendingRequest = true;
      const newsItems = await ApiController.news.getNewsItemList(this.pageLimit, this.lastestDoc);

      newsItems?.docs.map(doc =>
        this.newsList.push(({ id: doc.id, ...doc?.data() } as unknown) as NewsItem)
      );

      if (!newsItems?.docs.length || newsItems.docs.length < this.pageLimit) {
        this.endOfList = true;
        this.pendingRequest = false;
        return;
      }

      this.newsItemCounter += this.pageLimit;
      this.pendingRequest = false;
    },
    async deleteItem(id: string) {
      this.newsList = this.newsList.filter(item => item.id !== id);
      await ApiController.news.deleteNewsItem(id);
      ElNotification({
        title: "Success",
        message: "Deleted newsitem",
        type: "success"
      });
    }
  },

  watch: {
    newsItemCounter: async function() {
      if (this.newsList.length) {
        const latest = this.newsList[this.newsList.length - 1].id;
        this.lastestDoc = await newsCollection.doc(latest).get();
      }
    }
  },
  created() {
    this.getNewsItems();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
});
