
import { defineComponent } from "vue";
import { Parallax, AchievementCounter, Tabs } from "@/components";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import NewsOverview from "./overviews/NewsOverview.vue";
import EventsOverview from "./overviews/EventsOverview.vue";
import UsersOverview from "./overviews/UsersOverview.vue";

export default defineComponent({
  name: "Manage",
  components: { Parallax, AchievementCounter, Tabs, NewsOverview, EventsOverview, UsersOverview },
  data() {
    return {
      currentTab: this.$t("manage.tabs.users"),
      timestampToDate
    };
  },
  methods: {
    changeTab(nextTab: string) {
      this.currentTab = nextTab;
    }
  },
  computed:{
    isMobile(){
      if(window.innerWidth > 767){
        return false
      }
      return true;
    }
  }
});
