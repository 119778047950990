
import { defineComponent } from "vue";
import { usersCollection } from "@/plugins/firebase";
import { UserData } from "@/apiServices/interface";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import { ProfileCard } from "@/components";

export default defineComponent({
  name: "UsersOverview",
  components: { ProfileCard },
  data() {
    return {
      usersList: [] as UserData[],
      pendingRequest: false,
      timestampToDate
    };
  },
  methods: {
    async getUsers() {
      if (this.pendingRequest) {
        return;
      }
      try {
        this.pendingRequest = true;
        const users = await usersCollection.get();

        users.docs.map(doc =>
          this.usersList.push(({ id: doc.id, ...doc?.data() } as unknown) as UserData)
        );
      } catch (error) {
        console.warn(error);
      }
      this.pendingRequest = false;
    }
  },
  created() {
    this.getUsers();
  }
});
