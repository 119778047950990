
import { Parallax, Editor } from "@/components";
import { NewsItemPost } from "@/apiServices/interface";
import { newsCollection, storage } from "@/plugins/firebase";
import router from "@/router";
import { defineComponent } from "@vue/runtime-core";
import ApiController from "@/apiServices/ApiController";
import { FormActions } from "vee-validate";

export default defineComponent({
  name: "AddNewsItem",
  components: { Parallax, Editor },
  props: ["edit", "newsItemId"],
  data() {
    return {
      schema: {
        title: "required|min:3|max:100|alpha_spaces"
      },
      data: {
        title: "",
        shortDesc: "",
        body: ""
      },
      pendingRequest: false,
      isSubmitted: false,
      itemId: this.newsItemId ?? "",
      basePictureUrl: "",
      displayEditor: false,
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  methods: {
    async uploadNewPicture(val: any) {
      try {
        const storageRef = storage.ref().child(`news/${this.itemId}/${val.file.uid}`);
        const uploadedPicture = await storageRef.put(val.file);
        if (!this.basePictureUrl) this.basePictureUrl = await uploadedPicture.ref.getDownloadURL();
      } catch (err) {
        console.warn(err);
      }
    },
    handleRemove(file: any) {
      const storageRef = storage.ref().child(`news/${this.itemId}/${file.uid}`);
      storageRef.delete();
    },
    async handlePictureCardPreview(file: any) {
      const storageRef = storage.ref().child(`news/${this.itemId}/${file.uid}`);
      const pictureUrl = await storageRef.getDownloadURL();

      this.dialogImageUrl = pictureUrl;
      this.dialogVisible = true;
    },
    updateBodyContent(bodytext: string) {
      this.data.body = bodytext;
    },
    async addNewsItem(
      values: { title: string; shortDesc: string; newsDate: string },
      { resetForm }: FormActions<Record<string, unknown>>
    ) {
      if (this.pendingRequest) {
        return;
      }
      const newsItem: NewsItemPost = {
        title: values.title,
        body: JSON.stringify(this.data.body),
        createdAt: new Date(),
        description: this.data.shortDesc,
        basePictureUrl: this.basePictureUrl
      };
      this.pendingRequest = true;
      ApiController.news.updateNewsItem(this.itemId, newsItem);
      this.pendingRequest = false;
      this.data = { title: "", shortDesc: "", body: "" };
      this.isSubmitted = true;
      resetForm();
      router.push("/manage");
    }
  },
  async created() {
    if (this.newsItemId && this.edit) {
      const item = await ApiController.news.getNewsItem(this.newsItemId);
      this.data.title = item?.title ?? "";
      this.data.shortDesc = item?.description ?? "";
      this.data.body = item?.body ?? "";
      this.displayEditor = true;
      return;
    }

    try {
      const createdDoc = await newsCollection.add({});
      this.itemId = createdDoc.id;
      this.displayEditor = true;
    } catch (error) {
      console.warn(error);
    }
  },
  async beforeUnmount() {
    if (!this.isSubmitted) {
      try {
        await newsCollection.doc(this.itemId).delete();
      } catch (error) {
        console.warn(error);
      }
    }
  }
});
