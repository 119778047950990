
import ApiController from "@/apiServices/ApiController";
import { Parallax, Editor } from "@/components";
import { EventItemPost } from "@/apiServices/interface";
import { eventsCollection, storage } from "@/plugins/firebase";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import router from "@/router";
import { defineComponent } from "@vue/runtime-core";
import { FormActions } from "vee-validate";

export default defineComponent({
  name: "AddEventItem",
  components: { Parallax, Editor },
  props: ["eventItemId", "edit"],
  data() {
    return {
      schema: {
        title: "required|min:3|max:100|alpha_spaces",
        eventDate: "required"
      },
      data: {
        title: "",
        eventDate: new Date(),
        shortDesc: "",
        body: ""
      },
      pendingRequest: false,
      isSubmitted: false,
      itemId: this.eventItemId ?? "",
      basePictureUrl: "",
      displayEditor: false,
      dialogImageUrl: "",
      dialogVisible: false
    };
  },
  methods: {
    async uploadNewPicture(val: any) {
      try {
        const storageRef = storage.ref().child(`events/${this.itemId}/${val.file.uid}`);
        const uploadedPicture = await storageRef.put(val.file);
        if (!this.basePictureUrl) this.basePictureUrl = await uploadedPicture.ref.getDownloadURL();
      } catch (err) {
        console.warn(err);
      }
    },
    handleRemove(file: any) {
      const storageRef = storage.ref().child(`events/${this.itemId}/${file.uid}`);
      storageRef.delete();
    },
    async handlePictureCardPreview(file: any) {
      const storageRef = storage.ref().child(`events/${this.itemId}/${file.uid}`);
      const pictureUrl = await storageRef.getDownloadURL();

      this.dialogImageUrl = pictureUrl;
      this.dialogVisible = true;
    },
    updateBodyContent(bodytext: string) {
      this.data.body = bodytext;
    },
    async addEventItem(
      values: { title: string; shortDesc: string; eventDate: string },
      { resetForm }: FormActions<Record<string, unknown>>
    ) {
      if (this.pendingRequest) {
        return;
      }
      this.pendingRequest = true;
      const eventItem: EventItemPost = {
        title: values.title,
        body: JSON.stringify(this.data.body),
        createdAt: new Date(),
        eventDate: new Date(values.eventDate),
        description: this.data.shortDesc,
        basePictureUrl: this.basePictureUrl
      };
      ApiController.events.updateEventItem(this.itemId, eventItem);

      this.pendingRequest = false;
      this.data = { title: "", shortDesc: "", eventDate: new Date(), body: "" };
      this.isSubmitted = true;
      resetForm();
      router.push("/manage");
    }
  },
  async created() {
    if (this.eventItemId && this.edit) {
      const item = await ApiController.events.getEventItem(this.eventItemId);
      this.data.title = item?.title ?? "";
      this.data.eventDate = item?.eventDate
        ? new Date(timestampToDate(item?.eventDate))
        : new Date();
      this.data.shortDesc = item?.description ?? "";
      this.data.body = item?.body ?? "";
      this.displayEditor = true;
      return;
    }

    try {
      const createdDoc = await eventsCollection.add({});
      this.itemId = createdDoc.id;
      this.displayEditor = true;
    } catch (error) {
      console.warn(error);
    }
  },
  async beforeUnmount() {
    if (!this.isSubmitted) {
      try {
        await eventsCollection.doc(this.itemId).delete();
      } catch (error) {
        console.warn(error);
      }
    }
  }
});
